


/*
**
**
* TOP BAR
*
*/


#top-menu {

  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10001;
  background-color: $theme-color; 

  .account-info {

    float: left;
    width: 50%;
    padding: 9px 10px; 


    h1, h2, h3, h4, h5, p { 
            
      margin: 0;
      color: white !important;

    }

    p {
      font-size: 12px;
    }

  }

  .search-bar {

    float: right;
    text-align: right;
    
    width: 50%;     
    overflow: auto;


    .icon {

      float: right;
      text-align: center;
      width: 10%;
      padding: 15px 0;
      transition: all 0.3s ease;
      color: white;
      font-size: 18px;

      &:hover { background-color: #000; }      

    }

    .search-input{
      float: right;
      width: 90%;
      
      padding: 15px 0;

        .input-search {
          color: white;
          background-color: #0c3b56;
          border: 1px solid #092e44; 
          max-width: 90%;
        }
    }
  }

  

}



/**
*
* General: titles
*/

.section-title {
  background-color: #d4d4d4;
  text-align: center;
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    color: $theme-color;
    padding: 8px 0;
  }
}


.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1); 
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; 
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  } 
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}


.loading {

	background-color: $module-title-bg;
	color: $module-title-color !important;
	font-size: $font-size-base + 2;
	width: 300px;
	height: 250px;
	padding: 50px;
	text-align: center;

}

.loading::after {
	content: 'Cargando...';
}


.module-title {

	background-color: $module-title-bg;
	border-bottom: solid 3px #eee;

	h1, h2, h3, p, i, span {
		color: $module-title-color;    
	}

	span {
		font-size: $font-size-base + 5;
		color: $module-title-color !important;
	}

	h2.module-icon {
		font-size: 25px;
	}	

}


.modal-title
{
	padding: 10px;
	text-align: center;
	height: 40px;
	line-height: 30px;
	background-color: #eee;
	color: $theme-color;
}



#header-mobile {
	position: fixed;
	width: 100%;
	top: 0;
	background-color: #FFF !important;
	z-index: 390;	

	img {
		max-height: 65px;
		padding: 0 5px;
		margin: auto;
	}
}


#header-open-menu {

	height: 65px; 
	width: 55px; 	
	background: #ccc; 
	z-index: 390;

	a { 

		line-height: 65px;
		width: 100%;	

		i {
			font-size: 18px;
		}

	}	
}



.table-striped > tbody > tr > .danger,
.table-striped > tbody > .danger > td,
.table-striped > tbody > .danger > th {
  background-color: #f2dede !important;
}




/**
*
*
* NAV Main menu
*
*
*/



.main-nav {
    
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-size: 14px;
    }

    li.current 

    {

      background: #D4D4D4;

      > a {
        color: $theme-color;
        font-weight: bold;

        &:hover {
          background: #D4D4D4;
        }
      }

    }

    

    li.sub-menu {

      font-weight: bold;
      border-bottom: solid 2px #e6e6e6;


      > a:after { 

        font-family: "font-icons";        
        content: '\f105';        
        float: right;        

     }

     &.open {
        background: #EEE;
      }

      &.hover, &.open {
        > a:after {         
          content: '\f107';        
          }
        
      }




    }
   

    > li {
      > ul {
        > li {
          padding-left: 2em;
        }
      }
    }

    li > ul {
       
       display: none;
       list-style: none;

       > li > ul {
        display: none;
       }
    }


    > li a {      
      padding: 9px 20px;
      display: block;
      text-decoration: none;      

      &:hover {
        background: #eee;
      }

    }

}

